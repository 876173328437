<template>
    <div class="social-view">
        <CardComponent>
            <LoadingComponent
                v-show="loading"
                stroke-color="#579ae6"
                overlay="rgba(255, 255, 255, 0.8)"
            />
            <template #header>
                <RowComponent align="center">
                    <HelpComponent
                        markdown="social-sharing"
                        external-link="https://www.surveylegend.com/user-guide/sharing-surveys/"
                    />
                    <span>Share on social media</span>
                </RowComponent>
            </template>
            <InputComponent
                v-model="value"
                type="textarea"
                placeholder="Write your post here..."
                autosize
            />
            <RowComponent justify="space-between">
                <TooltipComponent label="Share with Facebook">
                    <ButtonComponent
                        class="social-view__network social-view__network--facebook"
                        shadow
                        @click="onClick('facebook')"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="none"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path
                                d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"
                            ></path>
                        </svg>
                    </ButtonComponent>
                </TooltipComponent>
                <TooltipComponent label="Share with Twitter">
                    <ButtonComponent
                        class="social-view__network social-view__network--twitter"
                        shadow
                        @click="onClick('twitter')"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="none"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path
                                d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"
                            ></path>
                        </svg> </ButtonComponent
                ></TooltipComponent>
                <TooltipComponent label="Share with LinkedIn">
                    <ButtonComponent
                        class="social-view__network social-view__network--linkedin"
                        shadow
                        @click="onClick('linkedin')"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="none"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path
                                d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"
                            ></path>
                            <rect x="2" y="9" width="4" height="12"></rect>
                            <circle cx="4" cy="4" r="2"></circle>
                        </svg>
                    </ButtonComponent>
                </TooltipComponent>
            </RowComponent>
        </CardComponent>
    </div>
</template>

<script>
import SurveyService from '@/services/survey-service'

export default {
    name: 'Social',

    metaInfo: {
        title: 'Social'
    },

    data() {
        return {
            loading: false,
            shortLink: '',
            value: ''
        }
    },

    async mounted() {
        this.loading = true

        try {
            const { data } = await SurveyService.getShortLink({ id: this.$route.params.id })

            this.shortLink = data
        } catch (error) {
            this.shortLink = this.longLink
        }

        this.loading = false
    },

    methods: {
        onClick(network) {
            let url

            switch (network) {
                case 'facebook':
                    url = `https://www.facebook.com/share.php?u=${this.shortLink}&quote=${this.value}`
                    break
                case 'twitter':
                    url = `https://twitter.com/intent/tweet?text=${this.value}&url=${this.shortLink}`
                    break
                case 'linkedin':
                    url = `https://www.linkedin.com/shareArticle?mini=true&url=${this.shortLink}`
                    break
            }

            this.openWindow(url)
        },

        openWindow(url, title = '', options = {}) {
            options = { width: 480, height: 541, ...options }

            options.top = window.innerHeight / 2 - options.height / 2 + window.screenTop
            options.left = window.innerWidth / 2 - options.width / 2 + window.screenLeft

            const optionsStr = Object.keys(options)
                .reduce((acc, key) => {
                    acc.push(`${key}=${options[key]}`)

                    return acc
                }, [])
                .join(',')

            const openedWindow = window.open(url, title, optionsStr)

            openedWindow.focus()

            return openedWindow
        }
    }
}
</script>

<style scoped lang="scss">
.social-view {
    .loading-component {
        border-radius: 6px;
    }

    .tooltip-component {
        flex-grow: 1;

        margin: 20px 15px 0 0;

        &:last-of-type {
            margin: 20px 0 0;
        }
    }
}

.social-view__network {
    width: 100%;

    padding: 8px 18px;

    svg {
        fill: #fff;
    }
}

.social-view__network--facebook {
    background-color: #3b5998;

    &:hover {
        background-color: lighten(#3b5998, 8) !important;
    }
}

.social-view__network--twitter {
    background-color: #55acee;

    &:hover {
        background-color: lighten(#55acee, 8) !important;
    }
}

.social-view__network--linkedin {
    background-color: #007bb6;

    &:hover {
        background-color: lighten(#007bb6, 8) !important;
    }
}
</style>
